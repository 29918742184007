.mat-expansion-panel {
  background: transparent !important;
  box-shadow: none !important;
  border-radius: 0px !important;
  border-bottom: 2px solid var(--hv-primary-font-color);
  &[ng-reflect-disabled="true"] {
    border-width: 1px;
    border-color: color-mix(in srgb, var(--hv-primary-font-color), transparent);
  }
  .mat-expansion-panel-header {
    height: 74px;
    padding: 0px 10px;
  }
  .mat-expansion-panel-header-title {
    align-items: center;
    flex-basis: 0;
    font-size: 16px;
    color: var(--hv-primary-font-color);
    font-weight: 500;
  }
  .mat-expansion-panel-header-description {
    align-items: center;
    flex-wrap: wrap;
    flex-basis: 0;
    font-weight: 400;
    font-size: 16px;
    color: #8f9199;
  }
  .mat-expansion-panel-body {
    padding: 0 10px 20px;
  }
  .action-center {
    margin-left: auto;
  }
  .mat-expansion-indicator::after {
    color: var(--hv-primary-font-color);
  }
}

.mat-expansion-panel
  .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled="true"]),
.mat-expansion-panel
  .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled="true"]),
.mat-expansion-panel:not(.mat-expanded)
  .mat-expansion-panel-header:hover:not([aria-disabled="true"]) {
  background: none !important;
}
