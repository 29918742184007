@use "@angular/material" as mat;

@import "angular-material-override.scss";
@import "styles-grouped.scss";

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body {
  --hv-primary-blue: #19384d;
  --hv-primary-darker-blue: #112533;
  --hv-secondary-blue: #5dc1ee;
  --hv-secondary-darker-blue: #09adea;
  --hv-wizard-blue: #3173d8;
  --hv-primary-gray: #edf2ff;
  --hv-primary-lighter-gray: #f7f9ff;
  --hv-primary-darker-gray: #d0d3d9;
  --hv-primary-dark: #000;
  --hv-primary-light: #fff;
  --hv-secondary-light: #f8f9fc;
  --hv-primary-warn: #d72424;
  --hv-primary-warn-light: #ffcdcd;

  &:not(.theme-alternate) {
    // Light base color
    --hv-primary-background-color: var(--hv-primary-lighter-gray);
    // Darker base color variant
    --hv-secondary-background-color: var(--hv-primary-gray);
    // Lighter base color variant
    --hv-accent-background-color: var(--hv-primary-light);
    --hv-primary-font-color: var(--hv-primary-dark);
    --hv-inverted-font-color: var(--hv-primary-light);
    --hv-brand-color: var(--hv-primary-blue);
    --hv-inverted-brand-color: var(--hv-secondary-blue);
  }
  &.theme-alternate {
    --hv-primary-background-color: #32373c;
    --hv-secondary-background-color: #282d32;
    --hv-accent-background-color: #37414a;

    --hv-primary-font-color: var(--hv-primary-light);
    --hv-inverted-font-color: var(--hv-primary-dark);

    --hv-brand-color: var(--hv-secondary-blue);
    --hv-inverted-brand-color: var(--hv-primary-blue);

    --hv-image-background-color: var(--hv-primary-gray);
    --hv-invert-filter: invert(0%);
  }
  background-color: var(--hv-primary-light);
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

// Specific component overrides, pieces that are not in line with the general theming

.flow {
  &-auto {
    overflow: auto;
  }
  &-hidden {
    overflow: hidden;
  }
  &-clip {
    overflow: clip;
  }
  &-x-clip {
    overflow-x: clip;
  }
  &-x-visible {
    overflow-x: visible;
  }
  &-y-clip {
    overflow-y: clip;
  }
  &-y-visible {
    overflow-y: visible;
  }
}

.text-align-center {
  text-align: center;
}

.mat-mdc-option {
  .mat-icon,
  img {
    margin-right: 8px !important;
  }
}
.cursor-pointer {
  cursor: pointer;
}

.workflow-type-choice {
  .mat-mdc-form-field-infix {
    padding-top: 8px !important;
    padding-bottom: 0px !important;
  }
}
.mdc-menu-surface.mat-mdc-select-panel {
  padding: 0px !important;
}

.remove {
  display: none !important;
}

.inset-wrap {
  position: absolute;
  inset: 0;
}

.w-full {
  width: 100% !important;
}

html,
body {
  height: 100%;
}
.text-color-muted {
  color: #797979;
}
/* Firefox */
input[type="number"] {
  appearance: textfield;
}
.initials-container {
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  line-height: normal;
  user-select: none;
  &[size="small"] {
    cursor: pointer;
    --_height: 40px;
    --_width: 40px;
    --_font-size: 1rem;
  }
  &[size="big"] {
    --_height: 100px;
    --_width: 100px;
    --_font-size: 2.5rem;
  }
  height: var(--_height, 80px);
  width: var(--_width, 80px);
  background-color: var(--hv-secondary-blue);
  font-size: var(--_font-size, 2rem);
  color: var(--hv-primary-light);
}

.mat-drawer-container {
  background-color: white !important;
  z-index: unset !important;
}

.alert-pointer {
  position: relative;
  --_polygon-width: 8px;
  --_polygon-height: 8px;
  &.large {
    --_polygon-width: 14px;
    --_polygon-height: 14px;
  }
  &::after {
    animation: rotate-y-axis 1s ease-in-out infinite;
    content: "";
    position: absolute;
    height: var(--_polygon-height);
    width: var(--_polygon-width);
    background: var(--hv-secondary-blue);
    top: 0;
    translate: -50%;
    clip-path: polygon(0% 0%, 100% 0%, 50% 100%);
    left: 50%;
  }
}

.backsplash-container {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: var(--hv-primary-gray);
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    animation: pulse 0.7s infinite ease-in-out alternate;
  }
  &.fade-out {
    animation: entity-fade 500ms ease-in-out 1 reverse;
  }
  z-index: 999999;
}

.lds-roller {
  --_loader-radius: 4.5rem;
  --_arrow-size: 1rem;
  --_animation-speed: 1s;
  animation: rotate-z-axis var(--_animation-speed) cubic-bezier(0.5, 0, 0.5, 1)
    infinite;

  display: inline-block;
  position: relative;
  width: var(--_loader-radius);
  height: var(--_loader-radius);
  .pseudo-config {
    content: " ";
    display: block;
    position: absolute;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    transform: translate(-50%, -50%);
  }
  .pseudo-mask-config {
    width: 0.625rem;
    height: 0.625rem;
    background: var(--hv-image-background-color, transparent);
  }
  div {
    width: fit-content;
    height: fit-content;
    isolation: isolate;
    &.light-arrow::after {
      content: "";
      position: absolute;
      width: var(--_arrow-size);
      height: var(--_arrow-size);
      transform: translateY(-50%);
      border-radius: 0;
      background-color: var(--hv-secondary-blue);
      clip-path: polygon(0% 0%, 100% 0%, 50% 100%);
      top: 50%;
      right: 0;
    }
    &.dark-arrow::after {
      content: "";
      position: absolute;
      width: var(--_arrow-size);
      height: var(--_arrow-size);
      border-radius: 0;
      background-color: var(--hv-primary-blue);
      transform: translateY(-50%);
      clip-path: polygon(0% 100%, 50% 0%, 100% 100%);
      top: 50%;
      left: 0;
    }
    &.dark-arrow::before {
      content: "";
      position: absolute;
      width: calc(var(--_arrow-size) + 0.25rem);
      height: calc(var(--_arrow-size) + 0.25rem);
      border-radius: 0;
      background-color: var(--hv-image-background-color, transparent);
      transform: translateY(-50%);
      clip-path: polygon(0% 100%, 50% 0%, 100% 100%);
      top: 49%;
      left: -3%;
    }
    &::after {
      @extend .pseudo-config;
    }
  }
  // First circle from light arrow
  div:nth-child(3)::after {
    background: var(--hv-primary-blue);
    top: 75%;
    left: 25%;
  }
  // First circle mask from light for dark mode
  div:nth-child(3)::before {
    @extend .pseudo-config;
    top: 75%;
    left: 25%;
    @extend .pseudo-mask-config;
  }
  // Second circle from light arrow
  div:nth-child(4)::after {
    background: var(--hv-primary-blue);
    top: 87.5%;
    left: 50%;
  }
  div:nth-child(4)::before {
    @extend .pseudo-config;
    top: 87.5%;
    left: 50%;
    @extend .pseudo-mask-config;
  }
  // Third circle from light arrow
  div:nth-child(5)::after {
    background: var(--hv-primary-blue);
    top: 75%;
    left: 75%;
  }
  div:nth-child(5)::before {
    @extend .pseudo-config;
    top: 75%;
    left: 75%;
    @extend .pseudo-mask-config;
  }
  //First circle from dark arrow
  div:nth-child(6)::after {
    background: var(--hv-secondary-blue);
    left: 25%;
    top: 25%;
  }
  //Second cirle from dark arrow
  div:nth-child(7)::after {
    top: 12.5%;
    background: var(--hv-secondary-blue);
    left: 50%;
  }
  //Third cirle from dark arrow
  div:nth-child(8)::after {
    background: var(--hv-secondary-blue);
    top: 25%;
    left: 75%;
  }
}

.grab {
  cursor: move !important;
  margin-left: 12px;
}

.frame-resize-component {
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: #17adea;
  height: 20px;
  width: 20px;
  border-top-left-radius: 8px;
  &::after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    width: 4px;
    background-color: var(--hv-primary-light);
    transform: rotate(45deg) translate(35%);
  }
  &::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    width: 4px;
    border-radius: 8px;
    background-color: var(--hv-primary-light);
    transform: rotate(45deg);
  }
}

.flowWrapper {
  height: 100%;
  width: 85%;
  margin: auto;
}

.mat-mdc-tooltip.tooltip {
  background-color: #09adea;
  color: var(--hv-primary-light);
  font-size: 14px;
}

// This is for browsers that support scrollbar customisation

// //
@supports not selector(::-webkit-scrollbar) {
  * {
    scrollbar-color: var(--hv-brand-color) transparent;
    scrollbar-width: thin;
  }
}
*::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  cursor: grab;
}
*::-webkit-scrollbar-track {
  margin-block: 4px;
  margin-inline: 4px;
  background: transparent;
}
*::-webkit-scrollbar-thumb {
  border: 2px solid transparent;
  background: var(--hv-brand-color);
  background-clip: padding-box;
  border-radius: 2rem;
}
*::-webkit-scrollbar-thumb:hover {
  border: 1px solid transparent;
}

.block-control {
  pointer-events: none;
}

// END OF CUSTOM USER CASES

.cdk-overlay-connected-position-bounding-box {
  z-index: unset !important;
}
.mat-mdc-tooltip-panel {
  z-index: 2000;
}

.mat-mdc-tooltip {
  margin: 4px !important;
}

//Used in workflow page, the hidden popout menu, do not remove
.hidden-panel-menu {
  transition: opacity 0.3s ease-in-out;
  color: var(--hv-primary-light);
  width: 165px;
  height: 165px;
  min-height: 165px;
  position: absolute;
  box-sizing: border-box;
  top: 100%;
  max-height: 250px;
  max-width: 250px;
  overflow: auto;
  resize: both;
  .hidden-panel-menu-pointer {
    width: 0;
    height: 0;
    margin-left: auto;
    margin-right: auto;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 16px solid #09adea;
    z-index: 1;
  }
  .hidden-panel-menu-content {
    background-color: #09adea;
    border-radius: 1rem;
    overflow: auto;
    padding: 8px;
    font-size: 12px;
    pre {
      margin: 0;
    }
  }
}
