mat-slide-toggle.mat-mdc-slide-toggle {
  --mat-switch-label-text-color: var(--hv-primary-font-color);
  .mdc-switch {
    --mdc-switch-track-width: 50px;
    --mdc-switch-track-height: 28px;
    --mdc-switch-track-shape: 1rem;
    --mdc-switch-unselected-track-color: var(--hv-primary-darker-gray);
    --mdc-switch-selected-track-color: var(--hv-secondary-darker-blue);
    --mdc-switch-selected-handle-color: var(--hv-secondary-darker-blue);
    --mdc-switch-selected-hover-handle-color: var(--hv-secondary-darker-blue);
    --mdc-switch-selected-focus-handle-color: var(--hv-secondary-darker-blue);
    --mdc-switch-selected-active-handle-color: var(--hv-secondary-darker-blue);

    --mdc-switch-selected-icon-color: var(--hv-primary-light);
  }
}

span.mdc-switch__handle-track {
  transform: translateX(10%);
}
button.mdc-switch--selected span.mdc-switch__handle-track {
  transform: translateX(90%);
}

mat-slide-toggle.mat-mdc-slide-toggle:not([disable="true"])
  div.mdc-form-field
  button.mdc-switch:not([disable="true"])[role="switch"]
  span.mdc-switch__track {
  &::before {
    background-color: transparent;
    border: 2px solid var(--mdc-switch-unselected-track-color);
  }
  &::after {
    background-color: transparent;
    border: 2px solid var(--mdc-switch-selected-track-color);
  }
}
