.mat-badge-primary span.mat-badge-content {
  color: var(--hv-secondary-blue);
  background-color: var(--hv-primary-blue);
}

.mat-badge-accent span.mat-badge-content {
  color: var(--hv-primary-blue);
  background-color: var(--hv-secondary-blue);
}

.mat-badge-warn span.mat-badge-content {
  color: var(--hv-primary-light);
  background-color: var(--hv-primary-warn);
}
