.mat-mdc-table {
  --mat-table-background-color: var(--hv-primary-background-color);
  isolation: isolate;
  --_table-font: color-mix(
    in srgb,
    var(--hv-secondary-background-color) 55%,
    var(--hv-primary-font-color) 45%
  );
}
.mat-mdc-table thead:not([disable]) {
  height: 60px;
  background-color: var(--hv-secondary-background-color);
  th.mat-mdc-header-cell {
    font-weight: 500;
    font-size: 16px;
    border-bottom-style: none;
    color: var(--_table-font);
    height: 60px;
  }
}
table.mat-mdc-table tr.mat-mdc-row {
  height: 60px;
  min-height: 60px;
  td.mat-mdc-cell:not(.actions) {
    font-size: 16px;
    color: var(--_table-font);
    font-weight: 400;
  }

  td.mat-mdc-cell:first-child {
    color: var(--hv-primary-font-color);
    font-weight: 500;
    padding-left: 20px;
  }
  .actions {
    > div {
      display: flex;
      justify-content: flex-end;
    }
    padding-right: 0rem;
  }
}
