.tags-chip {
  .mat-mdc-standard-chip {
    --mdc-chip-elevated-container-color: var(--hv-brand-color);
    --mdc-chip-label-text-color: var(--hv-primary-light);
    --mdc-chip-with-trailing-icon-trailing-icon-color: var(--hv-primary-blue);
    --mdc-chip-label-text-weight: 500;
    --mdc-chip-focus-state-layer-color: color-mix(
      in srgb,
      var(--hv-accent-background-color) 50%,
      transparent 50%
    );
  }
}

.chip-style-changes {
  .mat-mdc-chip-set {
    background-color: var(--hv-secondary-background-color);
    flex-wrap: nowrap;
    overflow: auto;
    border-radius: 2rem;
    padding: 0.25rem;
    justify-content: center;
    .mdc-evolution-chip-set__chips {
      flex-wrap: nowrap;
    }
    .mat-mdc-standard-chip {
      --mdc-chip-elevated-container-color: var(--hv-brand-color);
      --mdc-chip-label-text-color: var(--hv-primary-light);
      --mdc-chip-label-text-weight: 500;
      --mdc-chip-focus-state-layer-color: color-mix(
        in srgb,
        var(--hv-accent-background-color) 50%,
        transparent 50%
      );
    }
  }
}

.mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled) {
  .mdc-evolution-chip__action--presentational {
    cursor: pointer;
  }
}
