// This file was generated by running 'ng generate @angular/material:m3-theme'.
// Proceed with caution if making changes to this file.

@use "sass:map";
@use "@angular/material" as mat;

// Note: Color palettes are generated from primary: #5DC1EE, secondary: #19384D, tertiary: #112533
$_palettes: (
  primary: (
    0: #000000,
    10: #001e2b,
    20: #003547,
    25: #004157,
    30: #004d66,
    35: #005976,
    40: #006686,
    50: #0081a8,
    60: #2c9bc6,
    70: #50b6e3,
    80: #71d2ff,
    90: #c0e8ff,
    95: #e1f3ff,
    98: #f4faff,
    99: #fafcff,
    100: #ffffff,
  ),
  secondary: (
    0: #000000,
    10: #001e30,
    20: #133348,
    25: #203e54,
    30: #2c4a5f,
    35: #38556c,
    40: #446278,
    50: #5d7a92,
    60: #7794ad,
    70: #91afc8,
    80: #accae4,
    90: #cae6ff,
    95: #e6f2ff,
    98: #f6f9ff,
    99: #fcfcff,
    100: #ffffff,
  ),
  tertiary: (
    0: #000000,
    10: #081d2b,
    20: #1f3241,
    25: #2a3d4c,
    30: #364958,
    35: #415564,
    40: #4d6171,
    50: #66798a,
    60: #7f93a4,
    70: #9aaec0,
    80: #b5c9dc,
    90: #d1e5f8,
    95: #e6f2ff,
    98: #f6f9ff,
    99: #fcfcff,
    100: #ffffff,
  ),
  neutral: (
    0: #000000,
    10: #181c1f,
    20: #2c3134,
    25: #373c3f,
    30: #43474a,
    35: #4e5356,
    40: #5a5f62,
    50: #73787b,
    60: #8d9195,
    70: #a8acaf,
    80: #c3c7ca,
    90: #dfe3e6,
    95: #edf1f5,
    98: #f6fafd,
    99: #fafcff,
    100: #ffffff,
    4: #0a0f11,
    6: #0f1417,
    12: #1c2023,
    17: #262b2d,
    22: #313538,
    24: #353a3d,
    87: #d7dade,
    92: #e5e9ec,
    94: #ebeef2,
    96: #f0f4f8,
  ),
  neutral-variant: (
    0: #000000,
    10: #131d22,
    20: #283237,
    25: #333d42,
    30: #3e484e,
    35: #4a545a,
    40: #566066,
    50: #6e787f,
    60: #889299,
    70: #a2adb3,
    80: #bec8cf,
    90: #dae4eb,
    95: #e8f2f9,
    98: #f4faff,
    99: #fafcff,
    100: #ffffff,
  ),
  error: (
    0: #000000,
    10: #410002,
    20: #690005,
    25: #7e0007,
    30: #93000a,
    35: #a80710,
    40: #ba1a1a,
    50: #de3730,
    60: #ff5449,
    70: #ff897d,
    80: #ffb4ab,
    90: #ffdad6,
    95: #ffedea,
    98: #fff8f7,
    99: #fffbff,
    100: #ffffff,
  ),
);

$_rest: (
  secondary: map.get($_palettes, secondary),
  neutral: map.get($_palettes, neutral),
  neutral-variant: map.get($_palettes, neutral-variant),
  error: map.get($_palettes, error),
);
$_primary: map.merge(map.get($_palettes, primary), $_rest);
$_tertiary: map.merge(map.get($_palettes, tertiary), $_rest);

$light-theme: mat.define-theme(
  (
    color: (
      theme-type: light,
      primary: $_primary,
      tertiary: $_tertiary,
    ),
  )
);
$dark-theme: mat.define-theme(
  (
    color: (
      theme-type: dark,
      primary: $_primary,
      tertiary: $_tertiary,
    ),
  )
);
html {
  @include mat.all-component-themes($light-theme);
  .theme-alternate {
    @include mat.all-component-themes($dark-theme);
  }
}
