.mdc-menu-surface {
  --mat-select-panel-background-color: var(--hv-secondary-background-color);
  border-radius: 0.5rem;
}

div[role="menu"] {
  --mat-menu-container-color: var(--hv-secondary-background-color);
  --mat-menu-container-shape: 0.5rem;
  // These classes adjust the menu item buttons to work with the generic icon component. Without these changes
  // the generic icon won't be displayed properly.
  button.mat-mdc-menu-item > span {
    display: flex;
    flex-grow: 1;
    gap: 0.5rem;
  }
}
