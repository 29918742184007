.mat-mdc-dialog-container {
  --_action-orientation: row;
  --_action-gap: 0px;
  --_action-justify: space-between;
  --mdc-dialog-supporting-text-color: var(--hv-primary-font-color);
  --mdc-dialog-container-color: var(--hv-accent-background-color);
  --mdc-dialog-subhead-color: var(--hv-primary-font-color);
  .mat-mdc-dialog-surface {
    padding: 16px 59px 31px 59px !important;
  }
  .mat-mdc-dialog-title {
    padding: 0;
    --mdc-dialog-subhead-size: 24px;
    --mdc-dialog-subhead-tracking: 0.0075em;
    font-weight: 400 !important;
  }
  .mat-mdc-dialog-content {
    display: flex;
    padding: 4px 0px !important;
    flex-direction: column;
    gap: 1rem;
    > :first-child:not(ngx-mat-timepicker-content) {
      padding-top: 4px;
    }
    > :last-child:not(ngx-mat-timepicker-content) {
      padding-bottom: 4px;
    }
  }
  ngx-mat-timepicker-dialog {
    .mat-mdc-dialog-content {
      margin: 0px -59px;
      margin-top: -1rem;
    }
    .mat-mdc-dialog-actions {
      justify-content: space-between !important;
      margin-right: -42px !important;
      margin-left: -42px !important;
      margin-bottom: -30px !important;
      padding: 8px 16px;
      button {
        height: 48px;
      }
    }
  }
  @media (max-width: 450px) {
    --_action-gap: 8px;
    --_action-orientation: column;
    .mat-mdc-dialog-actions {
      align-items: stretch;
    }
  }
  .mat-mdc-dialog-actions {
    padding: 8px 0;
    margin-bottom: 0px !important;
    justify-content: space-between;
    gap: var(--_action-gap);
    flex-direction: var(--_action-orientation);
  }
}

// Removes the margin left from the right dialog button. We dont need the margin because we are using the gap
// property for the spacing.
.mat-mdc-dialog-actions
  button.mat-mdc-button-base
  + button.mat-mdc-button-base {
  margin-left: 0px;
}
.dialog-wrap {
  position: relative;
  min-height: 100px;
}

.dialog-subtitle {
  color: #8f9199;
  font-weight: 400 !important;
}
