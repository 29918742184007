div.mat-mdc-tab-body-wrapper {
  flex-grow: 1;
}
.adjust-tab .mat-mdc-tab-header {
  margin-inline: 2.5rem;
}

mat-tab-header {
  --mat-tab-header-active-ripple-color: var(--hv-brand-color);
  --mat-tab-header-inactive-ripple-color: var(--hv-brand-color);

  --mat-tab-header-active-label-text-color: var(--hv-brand-color);
  --mdc-tab-indicator-active-indicator-color: var(--hv-brand-color);

  --mat-tab-header-active-hover-label-text-color: var(--hv-brand-color);
  --mat-tab-header-active-focus-label-text-color: var(--hv-brand-color);

  --mat-tab-header-active-hover-indicator-color: var(--hv-brand-color);
  --mat-tab-header-active-focus-indicator-color: var(--hv-brand-color);
  
}
