//Adjust the font weight of the radio button label.
mat-radio-button.mat-mdc-radio-button {
  --mat-radio-label-text-color: var(--hv-primary-font-color) !important;

  --mdc-radio-selected-icon-color: var(--hv-brand-color) !important;
  --mdc-radio-selected-hover-icon-color: var(--hv-brand-color) !important;
  --mdc-radio-selected-focus-icon-color: var(--hv-brand-color) !important;
  --mdc-radio-selected-pressed-icon-color: var(--hv-brand-color) !important;

  --mat-radio-checked-ripple-color: var(--hv-primary-font-color) !important;
  --mat-radio-ripple-color: var(--hv-primary-font-color) !important;

  --mdc-radio-unselected-icon-color: var(--hv-primary-font-color) !important;
  --mdc-radio-unselected-hover-icon-color: var(
    --hv-primary-font-color
  ) !important;
  --mdc-radio-unselected-focus-icon-color: var(
    --hv-primary-font-color
  ) !important;
  --mdc-radio-unselected-pressed-icon-color: var(
    --hv-primary-font-color
  ) !important;
  --mdc-typography-body2-font-weight: 500 !important;
}

//These changes make the radio buttons a bit smaller so they better fit into forms.
.mat-mdc-radio-button .mdc-radio {
  padding: 4px !important;
}
//Reduces the size of the hover/touch detection a bit because in groups they can overlap with each other.
.mat-mdc-radio-touch-target {
  width: 40px !important;
  height: 40px !important;
}
