div.mdc-tooltip {
  user-select: none;
  &.warn-theme {
    --_warn-container-color: var(--hv-primary-warn);
    --_warn-font-color: var(--hv-primary-light);
  }
  --mdc-plain-tooltip-container-color: var(
    --_warn-container-color,
    var(--hv-brand-color)
  );
  --mdc-plain-tooltip-supporting-text-color: var(
    --_warn-font-color,
    var(--hv-inverted-font-color)
  );
  --mdc-plain-tooltip-supporting-text-weight: 600;
  --mdc-plain-tooltip-supporting-text-size: 14px;
}
