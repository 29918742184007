// By default the entity will fade in, if you want to do the fade out animation, just play the animation in reverse.
@keyframes entity-fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes drop-in {
  0% {
    transform: translate(-50%, -100%);
  }
  100% {
    transform: translate(-50%, 0);
  }
}

@keyframes rotate-y-axis {
  0%,
  100% {
    transform: rotateY(0);
  }
  50% {
    transform: rotateY(180deg);
  }
}
@keyframes rotate-z-axis {
  0% {
    transform: rotateZ(0deg);
  }
  100% {
    transform: rotateZ(360deg);
  }
}

@keyframes bounce-y-axis {
  0%,
  100% {
    translate: 0px -2px;
  }
  50% {
    translate: 0px 2px;
  }
}

@keyframes pulse {
  from {
    transform: scale(0.8);
  }
  to {
    transform: scale(1);
  }
}
