.mat-mdc-paginator.fixed {
  flex-shrink: 0;
  position: sticky;
  min-height: 64px;
  overflow: auto;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: var(--hv-secondary-background-color);
}
.mat-mdc-paginator-outer-container {
  height: 100%;
}
div.mat-mdc-paginator-container {
  color: var(--hv-primary-font-color);
  width: 85%;
  margin: auto;
  padding: 0;
  justify-content: space-between;
  @media (max-width: 555px) {
    justify-content: center;
    gap: 0.25rem;
  }
  .mat-mdc-paginator-range-actions {
    :nth-child(1) {
      order: 3;
    }
    :nth-child(2) {
      order: 1;
    }
    :nth-child(3) {
      order: 2;
    }
    :nth-child(4) {
      order: 4;
    }
    :nth-child(5) {
      order: 5;
    }
  }
}
