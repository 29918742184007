.flex {
  display: flex;
  &-row {
    @extend .flex;
    flex-direction: row;
  }
  &-column {
    @extend .flex;
    flex-direction: column;
  }
  &-row-reverse {
    @extend .flex;
    flex-direction: row-reverse;
  }
  &-column-reverse {
    @extend .flex;
    flex-direction: column-reverse;
  }
}
.pb {
  &-4 {
    padding-block: 0.25rem;
  }
  &-8 {
    padding-block: 0.5rem;
  }
  &-12 {
    padding-block: 0.75rem;
  }
  &-16 {
    padding-block: 1rem;
  }
}
.pi {
  &-4 {
    padding-inline: 0.25rem;
  }
  &-8 {
    padding-inline: 0.5rem;
  }
  &-12 {
    padding-inline: 0.75rem;
  }
  &-16 {
    padding-inline: 1rem;
  }
}
.gap {
  &-4 {
    gap: 0.25rem;
  }
  &-8 {
    gap: 0.5rem;
  }
  &-12 {
    gap: 0.75rem;
  }
  &-16 {
    gap: 1rem;
  }
}
.align {
  &-center {
    align-items: center;
  }
  &-baseline {
    align-items: baseline;
  }
  &-last-baseline {
    align-items: last baseline;
  }
  &-end {
    align-items: flex-end;
  }
  &-start {
    align-items: flex-start;
  }
  &-self {
    &-center {
      align-self: center;
    }
    &-end {
      align-self: flex-end;
    }
    &-start {
      align-self: flex-start;
    }
  }
}
.justify {
  &-center {
    justify-content: center;
  }
  &-baseline {
    justify-content: baseline;
  }
  &-end {
    justify-content: flex-end;
  }
  &-space-even {
    justify-content: space-evenly;
  }
  &-space-between {
    justify-content: space-between;
  }
  &-space-around {
    justify-content: space-around;
  }
}
.flex-grow {
  flex-grow: 1;
}
.flex-wrap {
  flex-wrap: wrap;
}
