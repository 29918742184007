body {
  --primary-button: #09adea;
  --accent-button: #2dae18;
  --warn-button: #d72424;
  --dark-button: #000000;
  --light-button: #ffffff;

  --primary-button-disabled: color-mix(
    in srgb,
    var(--primary-button) 50%,
    transparent 50%
  );
  --accent-button-disabled: color-mix(
    in srgb,
    var(--accent-button) 50%,
    transparent 50%
  );
  --warn-button-disabled: color-mix(
    in srgb,
    var(--warn-button) 50%,
    transparent 50%
  );
  --dark-button-disabled: color-mix(
    in srgb,
    var(--dark-button) 50%,
    transparent 50%
  );
  --light-button-disabled: color-mix(
    in srgb,
    var(--light-button) 50%,
    transparent 50%
  );
}

button.mat-mdc-button:not(:disabled) {
  color: var(--_custom-text-color, var(--mdc-text-button-label-text-color));
}
//This fixes the icon placement issue buttons have when used in combination with prefix/suffix icons
.mat-mdc-outlined-button,
.mat-mdc-unelevated-button,
.mat-mdc-raised-button {
  letter-spacing: 0 !important;
  &.density-high {
    min-height: 32px;
  }
  &.density-low {
    min-height: 42px;
  }
  min-height: 48px;
  .mdc-button__label {
    display: flex;
    align-items: center;
    gap: 4px;
  }
}
.mat-mdc-icon-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.1;
}

button[mat-flat-button].mat-mdc-unelevated-button.mdc-button,
button.mat-mdc-unelevated-button.mdc-button {
  --mdc-filled-button-container-shape: 0.5rem;
  --_disabled-font-color: color-mix(
    in srgb,
    var(--hv-primary-font-color) 50%,
    transparent 50%
  );
  &[color="primary"],
  &[ng-reflect-color="primary"] {
    --_background-color: var(--primary-button);
    --_disabled-background-color: var(--primary-button-disabled);
    --_font-color: var(--light-button);
    --_disabled-font-color: var(--light-button-disabled);
  }
  &[color="accent"],
  &[ng-reflect-color="accent"] {
    --_background-color: var(--accent-button);
    --_disabled-background-color: var(--accent-button-disabled);
    --_font-color: var(--light-button);
    --_disabled-font-color: var(--light-button-disabled);
  }
  &[color="warn"],
  &[ng-reflect-color="warn"] {
    --_background-color: var(--warn-button);
    --_disabled-background-color: var(--warn-button-disabled);
    --_font-color: var(--light-button);
    --_disabled-font-color: var(--light-button-disabled);
  }
  &[color="light"],
  &[ng-reflect-color="light"] {
    --_background-color: var(--light-button);
    --_disabled-background-color: var(--light-button-disabled);
    --_font-color: var(--dark-button);
    --_disabled-font-color: var(--dark-button-disabled);
  }
  &[color="dark-icon"],
  &[ng-reflect-color="dark-icon"] {
    --_font-color: var(--dark-button);
    --_disabled-font-color: var(--dark-button-disabled);
  }
  &[disabled="true"] img {
    filter: invert(0.6);
  }
  --mdc-filled-button-container-color: var(--_background-color);
  --mdc-text-button-label-text-color: var(--_font-color);
  --mdc-filled-button-label-text-color: var(--_font-color);
  --mdc-filled-button-disabled-container-color: var(
    --_disabled-background-color
  );
  --mdc-filled-button-disabled-label-text-color: var(--_disabled-font-color);
}
button.mat-mdc-raised-button {
  --mdc-protected-button-container-shape: 0.5rem;
  &[color="primary"],
  &[ng-reflect-color="primary"] {
    --_background-color: var(--primary-button);
    --_disabled-background-color: var(--primary-button-disabled);
    --_font-color: var(--light-button);
    --_disabled-font-color: var(--light-button-disabled);
  }
  &[color="accent"],
  &[ng-reflect-color="accent"] {
    --_background-color: var(--accent-button);
    --_disabled-background-color: var(--accent-button-disabled);
    --_font-color: var(--light-button);
    --_disabled-font-color: var(--light-button-disabled);
  }
  &[color="warn"],
  &[ng-reflect-color="warn"] {
    --_background-color: var(--warn-button);
    --_disabled-background-color: var(--warn-button-disabled);
    --_font-color: var(--light-button);
    --_disabled-font-color: var(--light-button-disabled);
  }
  &[color="light"],
  &[ng-reflect-color="light"] {
    --_background-color: var(--light-button);
    --_disabled-background-color: var(--light-button-disabled);
    --_font-color: var(--dark-button);
    --_disabled-font-color: var(--dark-button-disabled);
  }
  &[color="dark-icon"],
  &[ng-reflect-color="dark-icon"] {
    --_font-color: var(--dark-button);
    --_disabled-font-color: var(--dark-button-disabled);
  }
  &[disabled="true"] img {
    filter: invert(0.6);
  }
  --mdc-protected-button-container-color: var(--_background-color) !important;
  --mdc-protected-button-label-text-color: var(
    --_font-color,
    var(--hv-primary-light)
  ) !important;
  --mdc-protected-button-disabled-container-color: var(
    --_disabled-background-color
  ) !important;
  --mdc-protected-button-disabled-label-text-color: var(
    --_disabled-font-color
  ) !important;
}

//The colors overrides are different because not all buttons have the same approach to color variables
button.mat-mdc-outlined-button {
  --mdc-outlined-button-container-shape: 0.5rem;
  &[color="primary"],
  &[ng-reflect-color="primary"] {
    --_border-color: var(--primary-button);
    --_font-color: var(--primary-button);
    --_disabled-font-color: var(--primary-button-disabled);
    --_disabled-border-color: var(--primary-button-disabled);
  }
  &[color="accent"],
  &[ng-reflect-color="accent"] {
    --_border-color: var(--accent-button);
    --_font-color: var(--accent-button);
    --_disabled-font-color: var(--accent-button-disabled);
    --_disabled-border-color: var(--accent-button-disabled);
  }
  &[color="warn"],
  &[ng-reflect-color="warn"] {
    --_border-color: var(--warn-button);
    --_font-color: var(--warn-button);
    --_disabled-font-color: var(--warn-button-disabled);
    --_disabled-border-color: var(--warn-button-disabled);
  }
  &[color="light"],
  &[ng-reflect-color="dark"] {
    --_border-color: var(--light-button);
    --_font-color: var(--light-button);
    --_disabled-font-color: var(--light-button-disabled);
    --_disabled-border-color: var(--light-button-disabled);
  }
  --mdc-text-button-label-text-color: var(--_font-color);
  --mdc-outlined-button-label-text-color: var(--_font-color) !important;
  --mdc-outlined-button-outline-color: var(--_border-color) !important;
  --mdc-outlined-button-disabled-label-text-color: var(
    --_disabled-font-color
  ) !important;
  --mdc-outlined-button-disabled-outline-color: var(
    --_disabled-border-color
  ) !important;
}

button.mat-mdc-fab,
button.mat-mdc-mini-fab {
  &[color="primary"],
  &:not([matbadgecolor])[ng-reflect-color="primary"] {
    &[disabled="true"] {
      --_disabled-background-color: var(--primary-button-disabled);
      --_disabled-icon-color: var(--light-button-disabled);
    }
    --_background-color: var(
      --_disabled-background-color,
      var(--primary-button)
    );
    --_icon-color: var(--_disabled-icon-color, var(--light-button));
  }
  &[color="accent"],
  &[ng-reflect-color="accent"] {
    &[disabled="true"] {
      --_disabled-background-color: var(--accent-button-disabled);
      --_disabled-icon-color: var(--light-button-disabled);
    }
    --_background-color: var(
      --_disabled-background-color,
      var(--accent-button)
    );
    --_icon-color: var(--_disabled-icon-color, var(--light-button));
  }
  &[color="warn"],
  &[ng-reflect-color="warn"] {
    &[disabled="true"] {
      --_disabled-background-color: var(--warn-button-disabled);
      --_disabled-icon-color: var(--light-button-disabled);
    }
    --_background-color: var(--_disabled-background-color, var(--warn-button));
    --_icon-color: var(--_disabled-icon-color, var(--light-button));
  }
  &[color="light"],
  &[ng-reflect-color="light"] {
    &[disabled="true"] {
      --_disabled-background-color: var(--light-button-disabled);
      --_disabled-icon-color: var(--dark-button-disabled);
    }
    --_background-color: var(--_disabled-background-color, var(--light-button));
    --_icon-color: var(--_disabled-icon-color, var(--dark-button));
  }
  &[color="brand"],
  &[ng-reflect-color="brand"] {
    &[disabled="true"] {
      --_disabled-background-color: color-mix(
        in srgb,
        var(--hv-brand-color) 50%,
        transparent 50%
      );
      --_disabled-icon-color: var(--light-button-disabled);
    }
    --_background-color: var(
      --_disabled-background-color,
      var(--hv-brand-color)
    );
    --_icon-color: var(--_disabled-icon-color, var(--hv-inverted-font-color));
    --_custom-ripple-color: color-mix(
      in srgb,
      var(--hv-accent-background-color) 40%,
      transparent 60%
    );
  }
  --mdc-fab-container-color: var(--_background-color) !important;
  --mdc-fab-small-container-color: var(--_background-color) !important;
  --mat-mdc-fab-color: var(--_icon-color) !important;
  --mat-fab-small-foreground-color: var(--_icon-color) !important;
}
//The colors overrides are different because not all buttons have the same approach to color variables
button.mat-mdc-icon-button {
  &.density-mid {
    --mdc-icon-button-state-layer-size: 40px;
    padding: 8px;
  }
  &.density-high {
    --mdc-icon-button-state-layer-size: 32px;
    padding: 4px;
  }
  &.radius-x-small {
    border-radius: 0.25rem;
    --_custom-ripple-size: 0.25rem;
  }
  &.radius-small {
    border-radius: 0.5rem;
    --_custom-ripple-size: 0.5rem;
  }
  &.radius-mid {
    border-radius: 1rem;
    --_custom-ripple-size: 1rem;
  }
  span.mat-mdc-button-persistent-ripple {
    border-radius: var(--_custom-ripple-size, 50%);
  }
  --_icon-color: var(--hv-primary-font-color);
  --_disabled-icon-color: color-mix(
    in srgb,
    var(--hv-primary-font-color) 50%,
    transparent 50%
  );
  &[color="primary"],
  &[ng-reflect-color="primary"] {
    --_icon-color: var(--primary-button);
    --_disabled-icon-color: var(--primary-button-disabled);
  }
  &[color="accent"],
  &[ng-reflect-color="accent"] {
    --_icon-color: var(--accent-button);
    --_disabled-icon-color: var(--accent-button-disabled);
  }
  &[color="warn"],
  &:not([matbadgecolor])[ng-reflect-color="warn"] {
    --_icon-color: var(--warn-button);
    --_disabled-icon-color: var(--warn-button-disabled);
  }
  &[color="light"],
  &[ng-reflect-color="light"] {
    --_icon-color: var(--light-button);
    --_disabled-icon-color: var(--light-button-disabled);
  }
  &[color="dark"],
  &[ng-reflect-color="dark"] {
    --_icon-color: var(--dark-button);
    --_disabled-icon-color: var(--dark-button-disabled);
  }
  &[color="brand-background"],
  &[ng-reflect-color="brand-background"] {
    --_icon-color: var(--hv-inverted-font-color);
    --_disabled-icon-color: var(--light-button-disabled);
    background-color: var(--hv-brand-color);

    --_custom-ripple-color: color-mix(
      in srgb,
      var(--hv-accent-background-color) 40%,
      transparent 60%
    );
  }
  &[color="brand"],
  &[ng-reflect-color="brand"] {
    --_icon-color: var(--hv-brand-color);
    --_disabled-icon-color: color-mix(
      in srgb,
      var(--hv-brand-color) 50%,
      transparent 50%
    );
  }
  --mdc-icon-button-icon-color: var(--_icon-color) !important;
  --mdc-icon-button-disabled-icon-color: var(--_disabled-icon-color) !important;
}
//The colors overrides are different because not all buttons have the same approach to color variables
generic-icon {
  &[color="primary"],
  &[ng-reflect-color="primary"] {
    color: var(--primary-button);
  }
  &[color="primary-dark"],
  &[ng-reflect-color="primary-dark"] {
    color: var(--hv-primary-blue);
  }
  &[color="accent"],
  &[ng-reflect-color="accent"] {
    color: var(--accent-button);
  }
  &[color="warn"],
  &:not([matbadgecolor])[ng-reflect-color="warn"] {
    color: var(--warn-button);
  }
  &[color="dark"],
  &[ng-reflect-color="dark"] {
    color: var(--dark-button);
  }
  &[color="light"],
  &[ng-reflect-color="light"] {
    color: var(--light-button);
  }
  &[color="font"],
  &[ng-reflect-color="font"] {
    color: var(--hv-primary-font-color);
  }
  &[color="brand"],
  &[ng-reflect-color="brand"] {
    color: var(--hv-brand-color);
  }
}

//Fixes a very specific issue in the workflow board component. The icons for board interaction buttons without this fix
//arent centered.
button.mat-mdc-icon-button.icon-adjustment span.mdc-button__label {
  display: flex;
}

// Applies to generic icons that have the color attribute set
