.rotate {
  &.x-30 {
    --_rotate-value: x 30deg;
  }
  &.x-45 {
    --_rotate-value: x 45deg;
  }
  &.x-60 {
    --_rotate-value: x 60deg;
  }
  &.x-90 {
    --_rotate-value: x 90deg;
  }
  &.y-30 {
    --_rotate-value: y 30deg;
  }
  &.y-45 {
    --_rotate-value: y 45deg;
  }
  &.y-60 {
    --_rotate-value: y 60deg;
  }
  &.y-90 {
    --_rotate-value: y 90deg;
  }
  &.z-l-30 {
    --_rotate-value: z -30deg;
  }
  &.z-l-45 {
    --_rotate-value: z -45deg;
  }
  &.z-l-60 {
    --_rotate-value: z -60deg;
  }
  &.z-l-90 {
    --_rotate-value: z -90deg;
  }
  &.z-r-30 {
    --_rotate-value: z 30deg;
  }
  &.z-r-45 {
    --_rotate-value: z 45deg;
  }
  &.z-r-60 {
    --_rotate-value: z 60deg;
  }
  &.z-r-90 {
    --_rotate-value: z 90deg;
  }

  rotate: var(--_rotate-value);
}

.mirror {
  &.-x {
    --_scale-value: -1 1 1;
  }
  &.-y {
    --_scale-value: 1 -1 1;
  }
  &.-z {
    --_scale-value: 1 1 -1;
  }
  scale: var(--_scale-value);
}
