//Wizard highlight and basic block classes
.wizard-highlight {
  z-index: 1500 !important;
  position: relative;
}
.wizard-entity-highlight {
  box-shadow: inset 0px 0px 0.25rem 0.125rem var(--hv-wizard-blue);
}
.va-m {
  vertical-align: middle;
}

.wizard-highlight-block {
  //Activated by the disabled option in the guide
  > * {
    pointer-events: none;

    button {
      opacity: 0.35;
      pointer-events: none;
    }
  }
}

.wizard-highlight-block-background {
  background-color: var(--hv-primary-light);
}
// These classes are used for the wizard to disable a part of the buttons and guide the user to the wanted buttons

.wizard-highlight-block-navigate {
  button:not([data-wizard-target="entity-navigate-interaction"]) {
    pointer-events: none;
    opacity: 0.55;
  }
  button[data-wizard-target="entity-navigate-interaction"] {
    @extend .wizard-entity-highlight;
  }
}
.wizard-highlight-block-edit {
  button:not([data-wizard-target="edit-entity-interaction"]) {
    pointer-events: none;
    opacity: 0.5;
  }
  button[data-wizard-target="edit-entity-interaction"] {
    @extend .wizard-entity-highlight;
  }
}

// Workflow entities special cases

.wizard-highlight-block-flow-type-change {
  mat-form-field.workflow-type-choice {
    pointer-events: none;
    opacity: 0.5;
    * {
      pointer-events: none !important;
    }
  }
}

.wizard-highlight-block-entity-item-list {
  card-item {
    pointer-events: none;
  }
}
.wizard-highlight-block-toolbar-actions {
  background-color: #f8f9fc;
  button:not([data-wizard-target="trigger-interaction"]) {
    pointer-events: none;
    opacity: 0.5;
  }
}
.wizard-highlight-block-toolbar-triggers {
  button:not([data-wizard-target="action-interaction"]) {
    pointer-events: none;
    opacity: 0.5;
  }
  button[data-wizard-target="action-interaction"] {
    @extend .wizard-entity-highlight;
  }
}
.wizard-highlight-block-entity-items {
  div.block {
    opacity: 0.5;
    pointer-events: none;
  }
}
.wizard-highlight-block-bottom-menu {
  div.menu.bottom {
    opacity: 0.4;
    pointer-events: none;
  }
}
.wizard-highlight-block-show-first-list {
  card-item {
    &:not(:first-child) {
      > div {
        pointer-events: none;
        opacity: 0.5;
        background: transparent;
      }
    }
    &:first-child {
      > div > div:nth-child(2) {
        button {
          pointer-events: none;
          opacity: 0.5;
        }
      }
    }
  }
}

//Template special cases
.wizard-highlight-block-template-add-marker {
  button:not([wizard-template-add-marker-ignore]):not([disabled="true"]),
  mat-slide-toggle {
    pointer-events: none;
    opacity: 0.25;
  }
}
.wizard-highlight-block-template-add-value {
  button:not([wizard-template-add-value-ignore]):not([disabled="true"]),
  mat-slide-toggle {
    pointer-events: none;
    opacity: 0.25;
  }
}
.wizard-highlight-block-template-add-table {
  button:not([wizard-template-add-table-ignore]):not([disabled="true"]),
  mat-slide-toggle {
    pointer-events: none;
    opacity: 0.25;
  }
}
.wizard-highlight-block-template-previous {
  button:not([wizard-template-previous-ignore]):not([disabled="true"]),
  mat-slide-toggle {
    pointer-events: none;
    opacity: 0.25;
  }
  button[wizard-template-previous-ignore] {
    @extend .wizard-entity-highlight;
  }
}
.wizard-highlight-block-template-add-header {
  div.element:not(.selected) {
    pointer-events: none;
  }
  button:not([wizard-template-add-header-ignore]):not([disabled="true"]),
  mat-form-field {
    pointer-events: none;
    opacity: 0.5;
  }
}
.wizard-highlight-block-template-add-column {
  div.element:not(.selected) {
    pointer-events: none;
  }
  button:not([wizard-template-add-column-ignore]):not(
      [wizard-template-remove-column-ignore]
    ):not([disabled="true"]),
  mat-form-field {
    pointer-events: none;
    opacity: 0.5;
  }
}
.wizard-highlight-block-template-save-table {
  button:not([wizard-template-save-table-ignore]):not([disabled="true"]),
  mat-form-field {
    pointer-events: none;
    opacity: 0.5;
  }
}
.wizard-highlight-block-template-validate {
  button:not([wizard-template-validate-ignore]):not([disabled="true"]),
  mat-slide-toggle {
    pointer-events: none;
    opacity: 0.5;
  }
}
.wizard-highlight-block-template-validate-toggle {
  div.element {
    pointer-events: none;
  }
  button:not([disabled="true"]) {
    pointer-events: none;
    opacity: 0.5;
  }
}
.wizard-highlight-block-template-output-data {
  button:not([wizard-template-output-data-ignore]):not([disabled="true"]),
  mat-slide-toggle {
    pointer-events: none;
    opacity: 0.5;
  }
}
.wizard-highlight-block-template-existing-elements {
  .element {
    pointer-events: none;
  }
}
.wizard-highlight-block-template-free-roam {
  button {
    &[wizard-template-output-data-ignore],
    &[wizard-template-save-data-ignore] {
      pointer-events: none;
      opacity: 0.25;
    }
  }
}
.wizard-highlight-block-template-save-data {
  background-color: var(--hv-primary-light);
  button:not([wizard-template-save-data-ignore]):not([disabled="true"]) {
    pointer-events: none;
    opacity: 0.25;
  }
}
//Menu special case handling
.wizard-highlight-block-menu-body {
  pointer-events: none;
}
.wizard-highlight-block-all-but-save {
  button:not([data-wizard-target="save-changes-interaction"]) {
    pointer-events: none;
    opacity: 0.5;
  }
  button[data-wizard-target="save-changes-interaction"] {
    @extend .wizard-entity-highlight;
  }
}
.wizard-highlight-block-all-but-history {
  button:not([data-wizard-target="show-history-interaction"]) {
    pointer-events: none;
    opacity: 0.25;
  }
}
//Conversion custom css user cases
.wizard-highlight-block-all-but-upload {
  input,
  button:not([data-wizard-target="conversion-input-upload-interaction"]) {
    pointer-events: none !important;
    opacity: 0.5;
  }
  button[data-wizard-target="conversion-input-upload-interaction"] {
    @extend .wizard-entity-highlight;
  }
}
.wizard-highlight-block-input-upload {
  button[data-wizard-target="conversion-input-upload-interaction"],
  input {
    pointer-events: none !important;
    opacity: 0.5;
  }
  button[data-wizard-target="add-specification-entity-interaction"] {
    @extend .wizard-entity-highlight;
  }
}
.wizard-highlight-block-all-but-menu {
  button:not([data-wizard-target="specification-entity-interaction"]) {
    pointer-events: none;
    opacity: 0.5;
  }
  button[data-wizard-target="specification-entity-interaction"] {
    box-shadow: inset 0px 0px 0.25rem 0.125rem var(--hv-wizard-blue);
  }
}
.wizard-highlight-block-all-but-field {
  button:not(
      [data-wizard-target="specification-fields-selection-interaction"]
    ) {
    pointer-events: none;
    opacity: 0.5;
  }
  button[data-wizard-target="specification-fields-selection-interaction"] {
    @extend .wizard-entity-highlight;
  }
}
.wizard-highlight-block-all-but-config {
  button:not(
      [data-wizard-target="specification-configure-entity-interaction"]
    ) {
    pointer-events: none;
    opacity: 0.5;
  }
  button[data-wizard-target="specification-configure-entity-interaction"] {
    @extend .wizard-entity-highlight;
  }
}
.wizard-highlight-block-tab-interaction {
  mat-tab-header.mat-mdc-tab-header
    div.mdc-tab.mat-mdc-tab
    span.mdc-tab__content {
    pointer-events: none;
    opacity: 0.75;
  }
}
.wizard-highlight-block-all-but-toolbar {
  mat-tab-header.mat-mdc-tab-header
    div.mdc-tab.mat-mdc-tab
    span.mdc-tab__content {
    pointer-events: none;
    opacity: 0.75;
  }
  div[data-wizard-target="conversion-configuration-toolbar-interaction"] {
    @extend .wizard-entity-highlight;
  }
}

.wizard-highlight-block-all-but-concat {
  mat-tab-header.mat-mdc-tab-header div.mdc-tab.mat-mdc-tab,
  mat-tab-header.mat-mdc-tab-header
    div.mdc-tab.mat-mdc-tab
    span.mdc-tab__content {
    pointer-events: none;
    opacity: 0.75;
  }
  button:not(
      [data-wizard-target="configuration-function-ConcatenatedValue-interaction"]
    ) {
    pointer-events: none;
    opacity: 0.5;
  }
  button[data-wizard-target="configuration-function-ConcatenatedValue-interaction"] {
    @extend .wizard-entity-highlight;
  }
}

.wizard-highlight-block-all-but-insert {
  mat-tab-header.mat-mdc-tab-header div.mdc-tab.mat-mdc-tab,
  mat-tab-header.mat-mdc-tab-header
    div.mdc-tab.mat-mdc-tab
    span.mdc-tab__content {
    pointer-events: none;
    opacity: 0.75;
  }
  button:not([data-wizard-target="configuration-insert-base-interaction"]) {
    pointer-events: none;
    opacity: 0.5;
  }
  button[data-wizard-target="configuration-insert-base-interaction"] {
    @extend .wizard-entity-highlight;
  }
}

.wizard-highlight-block-all-but-constant-and-attach {
  mat-tab-header.mat-mdc-tab-header div.mdc-tab.mat-mdc-tab,
  mat-tab-header.mat-mdc-tab-header
    div.mdc-tab.mat-mdc-tab
    span.mdc-tab__content {
    pointer-events: none;
    opacity: 0.75;
  }
  div.tab-toolbar {
    button:not(
        [data-wizard-target="configuration-function-ConstantValue-interaction"]
      ) {
      pointer-events: none;
      opacity: 0.5;
    }
    button[data-wizard-target="configuration-function-ConstantValue-interaction"] {
      @extend .wizard-entity-highlight;
    }
  }
  div.tab-content {
    button {
      pointer-events: none;
      opacity: 0.5;
    }
    div[data-wizard-target="configuration-opts-interaction"] {
      button[data-wizard-target="configuration-insert-special-interaction"] {
        pointer-events: all;
        opacity: 1;
        @extend .wizard-entity-highlight;
      }
    }
  }
}

.wizard-highlight-block-all-but-constant-and-save {
  mat-tab-header.mat-mdc-tab-header div.mdc-tab.mat-mdc-tab,
  mat-tab-header.mat-mdc-tab-header
    div.mdc-tab.mat-mdc-tab
    span.mdc-tab__content {
    pointer-events: none;
    opacity: 0.75;
  }
  button:not([data-wizard-target="configuration-save-interaction"]) {
    opacity: 0.5;
    pointer-events: none;
  }
  button[data-wizard-target="configuration-save-interaction"] {
    @extend .wizard-entity-highlight;
  }
}
.wizard-highlight-block-all-but-conversion-output {
  button:not([data-wizard-target="conversion-output-gen-interaction"]) {
    opacity: 0.5;
    pointer-events: none;
  }
  button[data-wizard-target="conversion-output-gen-interaction"] {
    @extend .wizard-entity-highlight;
  }
}
.wizard-highlight-block-all-but-conversion-menu {
  @extend .wizard-entity-highlight;
}
.wizard-highlight-block-all-but-conversion-save {
  button[data-wizard-target="conversion-save-interaction"] {
    @extend .wizard-entity-highlight;
  }
  button:not([data-wizard-target="conversion-save-interaction"]) {
    pointer-events: none;
    opacity: 0.5;
  }
}
.wizard-highlight-block-all-but-deployment {
  button[data-wizard-target="conversion-deploy-interaction"] {
    @extend .wizard-entity-highlight;
  }
  button:not([data-wizard-target="conversion-deploy-interaction"]) {
    pointer-events: none;
    opacity: 0.5;
  }
}
.drop-zone {
  div.box-drag-placeholder {
    display: none !important;
  }
}
.cdk-drag-preview {
  z-index: 2000 !important;
}
.wizard-blurry-back {
  backdrop-filter: blur(1px);
  background-color: rgba(0, 0, 0, 0.3);
}
