form,
mat-form-field {
  &.density-low {
    @include mat.form-field-density(-1);
  }
  &.density-mid {
    @include mat.form-field-density(-2);
  }
  &.density-high {
    @include mat.form-field-density(-3);
  }
}

//Removes the padding of the select panel floating window.
//Added because of the workflow entities type filtering. The search bar is sticky to the top and has overflow issues without this.
.mdc-menu-surface.mat-mdc-select-panel {
  padding: 0px !important;
}

// Sets the styling for the code block form
div.monaco-editor {
  overflow: auto;
  border-radius: 0.25rem;
}

//Adjusts the color of the mat autocomplete select background

div.mat-mdc-autocomplete-panel[role="listbox"] {
  --mat-autocomplete-background-color: var(--hv-secondary-background-color);
}


.inner-form-chip {
  background-color: var(--hv-secondary-blue);
  color: white;
  font-weight: 600;
  border-radius: 1rem;
  padding: 0.125rem 0.5rem;
}
