.material-symbols-outlined,
.material-symbols-rounded,
.material-symbols-sharp {
  transition: font-variation-settings 300ms ease-in-out;
  &.size-xsm {
    --opsz-property: 18;
  }
  &.size-sm {
    --opsz-property: 20;
  }
  &.size-md {
    --opsz-property: 24;
  }
  &.size-lg {
    --opsz-property: 40;
  }
  &.size-xlg {
    --opsz-property: 48;
  }
  &.symbol-filled {
    --fill-property: 1;
  }
  &.weight-100 {
    --wght-property: 100;
  }
  &.weight-200 {
    --wght-property: 200;
  }
  &.weight-300 {
    --wght-property: 300;
  }
  &.weight-400 {
    --wght-property: 400;
  }
  &.weight-500 {
    --wght-property: 500;
  }
  &.weight-600 {
    --wght-property: 600;
  }
  &.weight-700 {
    --wght-property: 700;
  }
  &.weight-800 {
    --wght-property: 800;
  }
  &.gradiation-low {
    --grad-property: -25;
  }
  &.gradiation-mid {
    --grad-property: 0;
  }
  &.gradiation-high {
    --grad-property: 200;
  }
  font-variation-settings: "FILL" var(--fill-property, 0),
    "wght" var(--wght-property, 400), "GRAD" var(--grad-property, 0),
    "opsz" var(--opsz-property, 24);
}
