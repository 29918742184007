.mat-drawer-content {
  z-index: unset !important;
  background-color: var(--hv-primary-background-color);
}
.mat-drawer-backdrop {
  z-index: 9 !important;
}
.mat-drawer {
  --mat-sidenav-container-background-color: var(--hv-accent-background-color);
}
