.mat-mdc-snack-bar-container {
  margin: 32px 8px 8px 8px !important;
}
.mdc-snackbar__surface {
  box-shadow: none !important;
  padding-right: 0px !important;
}
.mdc-snackbar__label {
  padding-left: 8px !important;
  width: 80vw !important;
}
.snackbar {
  --mdc-snackbar-container-shape: 0.5rem;
  --mdc-snackbar-supporting-text-size: 16px;
  --mdc-snackbar-supporting-text-weight: 400;
}

.snackbar-success {
  --mdc-snackbar-container-color: var(--hv-secondary-darker-blue);
  --mdc-snackbar-supporting-text-color: var(--hv-primary-light);
}

.snackbar-failure {
  --mdc-snackbar-container-color: var(--hv-primary-warn);
  --mdc-snackbar-supporting-text-color: var(--hv-primary-light);
}

.snackbar-info {
  background-color: #efc050;
  color: var(--hv-primary-light);
}
