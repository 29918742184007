$fontConfig: mat.define-typography-config(
  $body-2:
    mat.define-typography-level(
      14px,
      24px,
      400,
      $font-family: "Roboto",
      $letter-spacing: 0.0179em
    ),
  $body-1:
    mat.define-typography-level(
      14px,
      24px,
      400,
      $font-family: "Roboto",
      $letter-spacing: 0.0179em
    ),
  $button:
    mat.define-typography-level(
      14px,
      14px,
      500,
      $font-family: "Roboto",
      $letter-spacing: 0.0893em
    ),
  $caption:
    mat.define-typography-level(
      12px,
      20px,
      400,
      $font-family: "Roboto",
      $letter-spacing: 0.0333em
    ),
);

// Foreground Elements

// Compute font config
@include mat.core();

// Theme Config
$mat-primary: (
  main: var(--hv-secondary-blue),
  lighter: var(--hv-secondary-blue),
  darker: var(--hv-primary-blue),
  // For slide toggle,
  contrast:
    (
      main: var(--hv-secondary-blue),
      lighter: var(--hv-primary-light),
      darker: var(--hv-primary-blue),
    ),
);
$theme-primary: mat.define-palette($mat-primary, main, lighter, darker);
$mat-accent: (
  main: #5ec1ee,
  lighter: #cfecfa,
  darker: #42abe7,
  200: #5ec1ee,
  // For slide toggle,
  contrast:
    (
      main: var(--hv-primary-blue),
      lighter: var(--hv-primary-light),
      darker: var(--hv-primary-blue),
    ),
);
$theme-accent: mat.define-palette($mat-accent, main, lighter, darker);

$mat-warn: (
  main: #f36082,
  lighter: #fbcfda,
  darker: #ed4465,
  200: #f36082,
  // For slide toggle,
  contrast:
    (
      main: var(--hv-secondary-darker-blue),
      lighter: var(--hv-primary-blue),
      darker: var(--hv-primary-blue),
    ),
);
$theme-warn: mat.define-palette($mat-warn, main, lighter, darker);
$theme: mat.define-light-theme(
  (
    color: (
      primary: $theme-primary,
      accent: $theme-accent,
      warn: $theme-warn,
    ),
    typography: $fontConfig,
    density: 0,
  )
);
$altTheme: mat.define-dark-theme(
  (
    color: (
      primary: $theme-primary,
      accent: $theme-accent,
      warn: $theme-warn,
    ),
    typography: $fontConfig,
    density: 0,
  )
);

// Theme Init
@include mat.all-component-themes($theme);

.theme-alternate {
  @include mat.all-component-colors($altTheme);
}
